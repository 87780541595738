import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@radix-ui/react-tooltip";
import { Info } from "lucide-react";
import { cn } from "../../utils/tailwindcss";

const labelVariants = cva("text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70");

const Label = React.forwardRef<
	React.ElementRef<typeof LabelPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
		VariantProps<typeof labelVariants> & {
			tooltipContent?: React.ReactNode;
		} & { required?: boolean }
>((props, ref) => {
	const { className, required, tooltipContent, ...rest } = props;

	return (
		<LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className, "flex items-center gap-1")} {...rest}>
			{props.children}
			{required && <span className="text-red-500">*</span>}
			{tooltipContent && (
				<TooltipProvider>
					<Tooltip>
						<TooltipTrigger asChild>
							<div>
								<Info size={12} />
							</div>
						</TooltipTrigger>
						<TooltipContent>
							<div className="p-2 mb-2 bg-white border rounded-md shadow-sm">{tooltipContent}</div>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			)}
		</LabelPrimitive.Root>
	);
});

Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
