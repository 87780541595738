import * as React from "react";
import { cn } from "../../utils/tailwindcss";

import InputError from "./input-error";
import clsx from "clsx";
import { Label } from "./label";

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	tooltipContent?: string;
	label?: string;
	errors?: { [x: string]: unknown };
	resize?: "vertical" | "horizontal" | "none";
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
	({ className, tooltipContent, label, required, errors, name, resize, ...props }, ref) => {
		return (
			<div className={clsx("flex flex-col gap-y-2 w-full", className)}>
				{label && (
					<Label tooltipContent={tooltipContent}>
						{label}
						{required && <span className="text-red-500">*</span>}
					</Label>
				)}
				<textarea
					className={cn(
						"flex min-h-[80px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
						{
							"resize-x": resize === "horizontal",
							"resize-y": resize === "vertical",
							"resize-none": resize === "none",
							resize: !resize,
						},
						className,
					)}
					ref={ref}
					required={required}
					name={name}
					{...props}
				/>
				<InputError name={name} errors={errors} />
			</div>
		);
	},
);
Textarea.displayName = "Textarea";

export { Textarea };
