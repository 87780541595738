import JSZip from "jszip";
import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Goodfie } from "../../types/event";
import { Spinner } from "../../components/base/spinner";
import { useParams } from "react-router-dom";
import { DownloadIcon, LinkedinIcon } from "lucide-react";

function getFileExtension(url: string) {
	const regex = /\.(\w+)(?:\?|#|$)/;
	const match = url.match(regex);
	return match ? match[1] : null;
}

export const GoodfieCard = ({ goodfies }: { goodfies: Goodfie[] }) => {
	const { id = "" } = useParams();

	const [goodfiesDownloading, setGoodfiesDownloading] = useState(false);

	const downloadGoodfie = async () => {
		setGoodfiesDownloading(true);
		if (goodfies?.length) {
			const zip = new JSZip();
			await Promise.all(
				goodfies.map(async (goodfie: Goodfie, index: number) => {
					const blob = await fetch(goodfie.url).then((res) => res.blob());
					zip.file(`${id}-goodfie-${index}.${getFileExtension(goodfie.url) ?? "jpg"}`, blob);
				}),
			);
			const zipData = await zip.generateAsync({
				type: "blob",
				streamFiles: true,
			});
			const link = document.createElement("a");
			link.href = window.URL.createObjectURL(zipData);
			link.download = `${id}-goodfies.zip`;
			link.click();
		}
		setGoodfiesDownloading(false);
	};

	const showcaseUrl = encodeURIComponent(`${process.env.REACT_APP_SHOWCASE_URL}${id}&date=${Date.now()}`);
	const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${showcaseUrl}`;

	return (
		<div className="flex flex-col items-center justify-center gap-6 px-4 py-6 mt-6 rounded-md bg-muted md:px-7 md:py-9 md:mt-9 md:gap-9">
			<style>
				{`.carousel-root .slide {
					display: flex;
					justify-content: center;
				}`}
			</style>

			{/* Carousel with responsive height */}
			<Carousel infiniteLoop showThumbs={false} className="w-full">
				{goodfies.map((goodfie) => (
					<div key={goodfie.url} className="relative overflow-hidden rounded-xl ">
						<img src={goodfie.url} className="relative object-contain w-full" alt="Goodfie" />
					</div>
				))}
			</Carousel>

			{/* Buttons */}
			<div className="flex flex-col gap-4 md:flex-row">
				<button
					className="flex items-center justify-center w-full gap-3 px-6 py-3 bg-white rounded-md md:w-64"
					onClick={downloadGoodfie}
				>
					{goodfiesDownloading ? (
						<div className="flex items-center gap-2">
							<Spinner />
							<span className="font-semibold">{"Downloading"}</span>
						</div>
					) : (
						<div className="flex items-center gap-2">
							<DownloadIcon />
							<span className="font-semibold">{"Download"}</span>
						</div>
					)}
				</button>

				<a
					className="flex items-center justify-center w-full md:w-64 py-3 px-6 rounded-md gap-3 bg-[#3194DC]"
					href={linkedinUrl}
					target="_blank"
					rel="noreferrer"
				>
					<LinkedinIcon fill="white" color="white" />
					<span className="font-semibold text-white">Share on LinkedIn</span>
				</a>
			</div>
		</div>
	);
};
