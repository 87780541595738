import { Event } from "../types/event";
import { CreateVolunteerSubmissionRequest, EventResponse } from "../types/submission";
import { VolunteerTicketResponse } from "../types/ticket";
import { PresignedUploadUrlResponse } from "../types/upload";
import qs from "qs";

type ApiConfig<TReqData> = {
	data?: TReqData;
	method?: "GET" | "POST" | "PATCH" | "DELETE" | "PUT" | "OPTIONS" | "HEAD";
	formData?: FormData;
	headers?: Record<string, string>;
	rawResponse?: boolean;
};

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function stringifyQueryString(query: Record<string, unknown>) {
	const simpleKeys = Object.keys(query).filter((key) => {
		function isBasic(value: unknown) {
			return (
				value === null ||
				value === undefined ||
				typeof value === "number" ||
				typeof value === "string" ||
				typeof value === "boolean" ||
				typeof value === "bigint"
			);
		}
		return (
			isBasic(query[key]) || (Array.isArray(query[key]) && (query[key] as []).every((value) => isBasic(value)))
		);
	});
	const complexKeys = Object.keys(query).filter((key) => {
		return !simpleKeys.includes(key);
	});
	const simpleQuery = simpleKeys.reduce(
		(agg, key) => {
			agg[key] = query[key];
			return agg;
		},
		{} as Record<string, unknown>,
	);
	const simpleQueryString = qs.stringify(simpleQuery, { encodeValuesOnly: true, arrayFormat: "comma" });
	const complexQuery = complexKeys.reduce(
		(agg, key) => {
			agg[key] = query[key];
			return agg;
		},
		{} as Record<string, unknown>,
	);
	const complexQueryString = qs.stringify(complexQuery, { encodeValuesOnly: true });
	if (simpleQueryString && complexQueryString) return `${simpleQueryString}&${complexQueryString}`;
	if (simpleQueryString) return simpleQueryString;
	if (complexQueryString) return complexQueryString;
	return "";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function api<TResData = any, TReqData = any>(
	endpoint: string,
	{ data, method, formData, headers: customHeaders = {}, rawResponse }: ApiConfig<TReqData> = {},
): Promise<TResData> {
	const config = {
		method: method || (data || formData ? "POST" : "GET"),
		body: formData || (data && JSON.stringify(data)),
	};

	const headers = new Headers();

	if (formData) {
		headers.append("Content-Type", "multipart/form-data");
	} else if (data && !formData) {
		headers.append("Content-Type", "application/json");
	}

	Object.entries(customHeaders).forEach(([key, value]) => headers.append(key, value));

	return fetch(`${BACKEND_URL}/${endpoint}`, {
		...config,
		headers,
		credentials: "include",
	}).then(async (response) => {
		if (rawResponse) return response;
		if (
			(response.headers.get("content-type") || "").includes("application/json;") &&
			(!response.headers.get("content-length") || parseInt(response.headers.get("content-length")!) > 0)
		) {
			const resData = await response.json();
			if (!response.ok) return Promise.reject(resData);
			return resData;
		}

		if (!response.ok) return Promise.reject(response);
		return response;
	});
}

export const endpoints = {
	event: {
		get: {
			query: async (eventId: string, { fields, expand }: { fields?: string[]; expand?: string[] } = {}) => {
				const queryString = stringifyQueryString({ fields: fields, expand: expand });

				return api<Event>(`volunteer_submission/fetch/event/${eventId}?${queryString}`);
			},
			getKeys: (eventId: string) => ["VOLUNTEER_SUBMISSION", "FETCH", "EVENT", eventId],
		},
	},

	volunteer_submission: {
		create: {
			query: async (request: CreateVolunteerSubmissionRequest) => {
				return api("volunteer_submission", {
					data: request,
				});
			},
			getKeys: () => ["VOLUNTEER_SUBMISSION", "CREATE"],
		},

		fetch_event: {
			query: async (eventId: string) => {
				return api<EventResponse>(`volunteer_submission/fetch/event/${eventId}`);
			},
			getKeys: (eventId: string) => ["VOLUNTEER_SUBMISSION", "FETCH", "EVENT", eventId],
		},
	},

	file_service: {
		signed_url_upload: {
			query: async ({ bucket, key, filePath }: { bucket: string; key: string; filePath: string }) => {
				return api<PresignedUploadUrlResponse>("volunteer_submission/signed-url/upload", {
					data: { bucket, key, filePath },
				});
			},
			getKeys: () => ["VOLUNTEER_SUBMISSION", "SIGNED_URL", "UPLOAD"],
		},
	},

	ticket: {
		getRegistrationTicket: {
			query: async ({ eventId, customerId }: { eventId: string; customerId: string }) => {
				return api<VolunteerTicketResponse>(`registration/event/${eventId}/customer/${customerId}`);
			},
			getKeys: (eventId: string, customerId: string) => ["REGISTRATIONS", "TICKET_DATA", eventId, customerId],
		},
	},
};
