import { Header } from "./header";
import { BeforeEvent } from "./before-event";
import { EventDetails } from "./event-details";
import { useParams } from "react-router-dom";
import { useEvent } from "../../hooks/use-event";
import { AfterEvent } from "./after-event";
import { EventState, EventWithState } from "../../types/event";
import { createContext, useContext } from "react";

export const VrpContext = createContext<{ event: EventWithState; isCompletedEvent: boolean } | null>(null);

export const VRP = () => {
	const { id: slotID = "" } = useParams();
	const { data: eventDetails } = useEvent(slotID);

	const isCompletedEvent = eventDetails?.state === EventState.COMPLETED;

	if (!eventDetails) return null;
	return (
		<VrpContext.Provider value={{ event: eventDetails, isCompletedEvent }}>
			<div className="flex flex-col items-center w-screen text-[#444444] font-buenos px-6 sm:p-0">
				<Header />
				<EventDetails />
				<AfterEvent />
				<BeforeEvent />
			</div>
		</VrpContext.Provider>
	);
};

export function useVrpContext() {
	const context = useContext(VrpContext);
	if (!context) {
		throw new Error("useVrpContext error");
	}
	return context;
}
