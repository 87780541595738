import { Event, EventState } from "../types/event";

export function getEventState(event: Event) {
	const now = new Date(),
		start = new Date(event.startTimeStamp),
		end = new Date(event.endTimeStamp);
	if (start > now) {
		return EventState.UPCOMING;
	} else if (end < now) {
		return EventState.COMPLETED;
	} else {
		return EventState.ONGOING;
	}
}
