import { Download, Play, Star, ThumbsUp, Upload, VideoIcon } from "lucide-react";
import { Tile } from "./tile";
import * as Dialog from "../../components/base/dialog";
import { useState } from "react";
import VolunteerForm from "../submission/[id]";
import { useVrpContext } from ".";
import ReactPlayer from "react-player/lazy";
import { CountdownText } from "./countdown-text";
import { submissionCounter } from "../../utils/submission-counter";

export const BeforeEvent = () => {
	const [openSubmissionForm, setOpenSubmissionForm] = useState(false);
	const [openNpoVideo, setOpenNpoVideo] = useState(false);
	const { isCompletedEvent, event } = useVrpContext();
	if (isCompletedEvent) {
		return null;
	}
	const kbyg = event.collaterals?.find((collateral) => collateral.collateralType === "kbyg")?.url;
	const nonProfitVideo =
		event.collaterals?.find((collateral) => collateral.collateralType === "npo_video")?.url ||
		event?.partner?.videoUrl;

	const { isDeadlinePassed } = submissionCounter(event.endTimeStamp, event.submissionDeadline);
	return (
		<div className="w-screen bg-white">
			<Dialog.Dialog open={openSubmissionForm} onOpenChange={setOpenSubmissionForm}>
				<Dialog.DialogContent className="max-w-2xl h-[85vh] overflow-scroll">
					<VolunteerForm className="" />
				</Dialog.DialogContent>
			</Dialog.Dialog>

			<Dialog.Dialog open={openNpoVideo} onOpenChange={setOpenNpoVideo}>
				<Dialog.DialogContent className="overflow-scroll min-w-fit h-fit">
					<div className="flex items-center justify-center p-2">
						<ReactPlayer controls url={nonProfitVideo} width="100%" />
					</div>
				</Dialog.DialogContent>
			</Dialog.Dialog>

			<div className="max-w-6xl px-4 mx-auto md:px-0">
				<div className="py-10 md:py-20">
					{kbyg || nonProfitVideo ? (
						<div className="mb-16 md:mb-20">
							<div className="mb-6 md:mb-11 text-xl md:text-2xl text-[#888888]">Resources</div>
							<div className="flex flex-col gap-6 md:gap-10">
								<Tile
									header="KBYG ( Know before you go )"
									icon={<Download />}
									iconBackgroundColor="#FFF0EB"
									text="A downloadable document is an end-to-end event guide for volunteers."
									buttonIcon={<Download size={18} />}
									buttonText="Download KBYG"
									onClick={() => window.open(kbyg, "_blank")}
									hidden={Boolean(!kbyg)}
								/>

								<Tile
									header="Nonprofit video"
									icon={<VideoIcon />}
									iconBackgroundColor="#FFF6D3"
									text="Watch a video introduction from the nonprofit partner."
									buttonIcon={<Play size={18} />}
									buttonText="Watch video"
									onClick={() => setOpenNpoVideo(true)}
									hidden={Boolean(!nonProfitVideo)}
								/>
							</div>
						</div>
					) : null}

					{/* Deliverables section */}
					<div>
						<div className="mb-6 md:mb-11 text-xl md:text-2xl text-[#888888]">Deliverables</div>
						<div className="flex flex-col gap-6 md:gap-10">
							<Tile
								header="Submissions"
								icon={<Upload />}
								iconBackgroundColor="#D6D8E5"
								text={<CountdownText />}
								buttonIcon={<Upload size={18} />}
								buttonText="Submission Form"
								hidden={isDeadlinePassed}
								onClick={() => setOpenSubmissionForm(true)}
							/>

							<Tile
								header="Rating and Feedback"
								icon={<Star />}
								iconBackgroundColor="#F4EFDD"
								text={"Your feedback is valuable and helps us improve our events."}
								buttonIcon={<ThumbsUp size={18} />}
								buttonText="Share Feedback"
								onClick={() =>
									window.open(process.env.REACT_APP_FEEDBACK_BASE_URL + event.id, "_blank")
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
