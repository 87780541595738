import isEmail from "validator/lib/isEmail";

export function isValidEmail(email: string) {
	return isEmail(email, { allow_utf8_local_part: true });
}
export function isValidGooderaEmail(email: string) {
	return isEmail(email, { allow_utf8_local_part: true, host_whitelist: ["goodera.com"] });
}
export function isValidGooderaHostEmail(email: string) {
	return isEmail(email, { allow_utf8_local_part: true, host_whitelist: ["gooderahost.com"] });
}
