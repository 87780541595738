import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppProvider from "./providers/AppProvider";

export default function App() {
	return (
		<AppProvider>
			<Outlet />
			<ToastContainer
				position="top-right"
				autoClose={1500}
				hideProgressBar={false}
				newestOnTop
				pauseOnHover={true}
				closeOnClick={true}
				theme="light"
			/>
		</AppProvider>
	);
}
