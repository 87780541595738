import { ReactNode } from "react";
import { Button } from "../../components/base/button";
import { cn } from "../../utils/tailwindcss";

export const Tile = ({
	header,
	icon,
	iconBackgroundColor,
	text,
	buttonIcon,
	buttonText,
	onClick = null,
	hidden = false,
	hideButton = false,
}: {
	header: string;
	icon: ReactNode;
	iconBackgroundColor: string;
	text: ReactNode;
	buttonIcon: ReactNode;
	buttonText: string;
	onClick?: (() => void) | null;
	hideButton?: boolean;
	hidden?: boolean;
}) => {
	if (hidden) return null;
	return (
		<div className="flex flex-col items-center w-full p-4 rounded-lg md:flex-row">
			<div
				className="flex items-center justify-center mb-4 rounded-lg md:mb-0 md:mr-4 w-14 h-14"
				style={{ background: iconBackgroundColor }}
			>
				{icon}
			</div>
			<div className="flex-grow text-center md:text-left">
				<h4 className="text-lg md:text-xl font-semibold text-[#444444]">{header}</h4>
				<p className="text-sm md:text-base text-[#666666]">{text}</p>
			</div>

			{!hideButton ? (
				<Button
					onClick={() => onClick?.()}
					variant={"muted"}
					className={cn(
						"mt-4 md:mt-0 bg-muted !text-sm md:!text-base !hover:bg-none cursor-default text-[#666666] !h-auto w-full md:w-64 justify-center py-4 px-6 rounded-md flex items-center gap-3",
						{
							"hover:bg-primary hover:text-white cursor-pointer": onClick,
						},
					)}
				>
					{buttonIcon}
					{buttonText}
				</Button>
			) : null}
		</div>
	);
};
