const ThankYouMessage = () => (
	<div className="text-center md:my-36">
		<img src={"/thank_you_page2.png"} alt="thank you" className="mx-auto" />
		<div className="mt-5 text-3xl font-medium">Thank you for volunteering with us</div>
		<div className="mt-3 text-sm font-medium">
			We sincerely hope that your volunteering journey with us only grows stronger.
		</div>
		<div className="mt-10 text-3xl font-medium text-primary">Keep powering the world of good</div>
		<div className="mt-3 mb-20 font-medium text-secondary">#DoGoodFeelGood</div>
	</div>
);

export default ThankYouMessage;
