import moment from "moment-timezone";
import { useVrpContext } from ".";
import { cn } from "../../utils/tailwindcss";
import { eventFormatMap } from "../../constants";

const Pill = ({ header, text }: { header: string; text: string }) => {
	return (
		<div className="mb-4 md:mb-0">
			<div className="text-[#666666] text-sm">{header}</div>
			<div className="text-lg font-semibold md:text-xl">{text}</div>
		</div>
	);
};

export const EventDetails = () => {
	const { event, isCompletedEvent } = useVrpContext();

	const timezone = moment.tz.guess();
	const startTimeString = moment(event?.startTimeStamp)
		.tz(timezone || event.timezone || "America/New_York")
		.format("MMM D | hh:mm");
	const endTimeString = moment(event.endTimeStamp)
		.tz(timezone || event.timezone || "America/New_York")
		.format("hh:mmA zz");

	return (
		<div
			className={cn("w-screen px-4 md:px-0", {
				"bg-[#EEFFFA]": isCompletedEvent,
			})}
		>
			<div className="max-w-6xl py-8 mx-auto md:py-11">
				<div className="w-full md:w-4/5">
					<div className="font-semibold text-[#222222] text-2xl md:text-3xl">{event?.name}</div>

					<div className="flex flex-col items-start justify-between gap-6 mt-8 md:flex-row md:items-center md:gap-0">
						<Pill header="Date and Time" text={`${startTimeString} - ${endTimeString}`} />
						<Pill header="Format" text={eventFormatMap[event.variant.format]} />
						<Pill
							header="Nonprofit partner"
							text={`${event.partner.name}, ${event.partner?.corporateAddress?.city}`}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
