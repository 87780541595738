type FileFormats =
	| 'application'
	| 'audio'
	| 'font'
	| 'example'
	| 'image'
	| 'message'
	| 'model'
	| 'multipart'
	| 'text'
	| 'video';

export function getFileFormat(file: File): FileFormats {
	const fileType = file.type;
	return fileType.split('/')[0] as FileFormats;
}

export async function getFileDetails(fileUrl: string) {
	const fileName = fileUrl.split('/').at(-1);
	let fileHead;
	try {
		fileHead = await fetch(fileUrl, { method: 'HEAD' });
		if (!fileHead.ok) {
			throw new Error();
		}
	} catch (err) {
		return {
			fileName,
		};
	}
	const fileType = fileHead.headers.get('Content-Type') ?? undefined;
	const fileSize = parseInt(fileHead.headers.get('Content-Length') ?? '-1');
	return {
		fileName,
		fileType,
		fileSize,
	};
}
