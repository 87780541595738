import { useForm, Controller } from "react-hook-form";
import { CreateVolunteerSubmissionForm, EventResponse } from "../../../types/submission";
import Input from "../../../components/base/input";
import { Textarea } from "../../../components/base/textarea";
import { Button } from "../../../components/base/button";
import { FileDrop } from "../../../components/base/file-drop";
import InputError from "../../../components/base/input-error";
import { isValidEmail } from "../../../utils/validations/email";
import moment from "moment";

const VolunteerInput = ({
	eventData,
	onSubmit,
	isLoading,
}: {
	eventData: EventResponse;
	onSubmit: (data: CreateVolunteerSubmissionForm) => Promise<void>;
	isLoading: boolean;
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
	} = useForm<CreateVolunteerSubmissionForm>();

	const isSubmissionPage = Boolean(window.location.href.split("/").find((a) => a === "submission"));
	return (
		<div>
			<div>
				<h2 className="mb-2 text-3xl font-bold text-gray-800">Upload Submission</h2>
				<p className="mb-8 text-lg text-gray-500">You are just minutes away from making an impact!</p>

				{isSubmissionPage ? (
					<div className="p-5 mb-8 bg-gray-100 border border-gray-300 rounded-lg">
						<p className="text-xl font-semibold text-gray-700">
							Event Name: <span className="font-normal">{eventData.name}</span>
						</p>
						<p className="text-xl font-semibold text-gray-700">
							Date:{" "}
							<span className="font-normal">
								{moment(eventData.startTimeStamp).format("MMMM Do YYYY, h:mm A")}
							</span>
						</p>
					</div>
				) : null}
			</div>

			<div className="flex flex-col gap-6">
				<Input
					label="Work email address"
					type="email"
					id="email"
					errors={errors}
					{...register("email", {
						required: { value: true, message: "Work email address is required" },
						validate: (email) => {
							if (!email || isValidEmail(email)) {
								return true;
							} else {
								return "Please add valid email address";
							}
						},
					})}
					placeholder="Enter your email address"
					required
				/>

				<Controller
					name="submissionFiles"
					control={control}
					rules={{ required: { value: true, message: "Add files to continue" } }}
					render={({ field: { name, onChange, value } }) => (
						<FileDrop
							label="Please add your file(s) here"
							description="Name the file as Topic_your name_company name"
							allowMulti
							name={name}
							required
							onChange={onChange}
							value={value}
							errors={errors}
						/>
					)}
				/>
				<InputError name="submissionFiles" errors={errors} />

				<Textarea
					label="Any message you would like to add?"
					id="additionalMessage"
					{...register("additionalMessage")}
					rows={4}
					placeholder="Optional"
				/>

				<Button onClick={handleSubmit(onSubmit)} className="w-full" isLoading={isLoading}>
					Upload
				</Button>
			</div>
		</div>
	);
};

export default VolunteerInput;
