import { ChangeEventHandler, ReactNode, useMemo } from "react";
import { Label } from "./label";
import { FileIcon, Plus, PlusIcon, Trash2 } from "lucide-react";
import mime from "mime";
import { toast } from "react-toastify";
import filesizeParser from "filesize-parser";

import { filesize } from "filesize";
import { getFileFormatFromType } from "../../utils/get-file-format";
import { useEnrichedFiles } from "../../hooks/use-enrich-file";
import { Spinner } from "./spinner";
import { Button } from "./button";

export type AdvancedFile = { id?: string; name: string; size: number; type: string } & (
	| { file?: null; url: string }
	| { file: File; url?: null }
);

export type AdvancedFileOrUrl = AdvancedFile | { url: string; file?: null };

type FileDropProps = {
	label?: string;
	id?: string;
	name?: string;
	description?: ReactNode;
	required?: boolean;
	allowedFileTypes?: string[];
	tooltipContent?: ReactNode;
	maxSize?: string;
	errors?: Record<string, unknown>;
} & (
	| {
			allowMulti: true;
			value: (AdvancedFile | { url: string })[];
			onChange: (files: AdvancedFile[]) => void;
	  }
	| {
			allowMulti?: false;
			value: (AdvancedFile | { url: string }) | null;
			onChange: (file: AdvancedFile | null) => void;
	  }
);

function RenderFilePreview({ file }: { file: AdvancedFile }) {
	const fileFormat = getFileFormatFromType(file.type);

	switch (fileFormat) {
		case "image": {
			return (
				<img
					src={file.url ? file.url : file.file ? URL.createObjectURL(file.file) : "default-thumbnail.png"}
					alt="Preview"
					className="object-cover w-24 h-24 rounded-md"
				/>
			);
		}
		case "video": {
			return (
				<video className="object-cover w-24 h-24 rounded-md">
					<source src={file.url ? `${file.url}#t=0.1` : URL.createObjectURL(file.file as File)} />
				</video>
			);
		}
		default: {
			return (
				<div className="flex items-center justify-center w-12 h-12 p-2 rounded-md bg-slate-200">
					<FileIcon />
				</div>
			);
		}
	}
}

function RenderFileDetails({ file }: { file: AdvancedFile }) {
	return (
		<div>
			<p className="w-64 truncate">{file.name} </p>
			{file.size > 0 ? (
				<p className="text-xs text-muted-foreground">{filesize(file.size, { standard: "jedec" })}</p>
			) : null}
		</div>
	);
}

function getFileTypeValidator(allowedFileTypes: string[]) {
	return (file: File) => allowedFileTypes.some((type) => type === file.type);
}

function getFileSizeValidator(maxSize: string) {
	const fileSize_ = filesizeParser(maxSize);
	return (file: File) => fileSize_ && file.size < fileSize_;
}

export function FileDrop({
	label,
	id,
	description,
	allowMulti,
	required,
	tooltipContent,
	maxSize,
	name,
	allowedFileTypes = [],
	errors,
	value,
	onChange,
}: FileDropProps) {
	const files = useMemo(() => {
		if (Array.isArray(value)) {
			return value;
		} else if (value) {
			return [value];
		}
		return [];
	}, [value]);

	const { enrichedFiles, isLoading } = useEnrichedFiles(files);

	const handleFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		if (!e.target.files || !e.target.files.length) {
			return;
		}
		const files = Array.from(e.target.files);
		if (files.length > 1) {
			if (!allowMulti) {
				toast.error("Only one file is allowed");
				return;
			}
		}
		if (allowedFileTypes.length && !files.every(getFileTypeValidator(allowedFileTypes))) {
			toast.error("Unsupported file type");
			return;
		}
		if (maxSize && !files.every(getFileSizeValidator(maxSize))) {
			toast.error(`Files should be less than ${maxSize}`);
			return;
		}
		if (allowMulti) {
			onChange([
				...enrichedFiles,
				...files.map<AdvancedFile>((file) => ({
					file,
					name: file.name,
					type: file.type,
					size: file.size,
				})),
			]);
		} else {
			const firstFile = files[0];
			onChange({
				file: firstFile,
				name: firstFile.name,
				type: firstFile.type,
				size: firstFile.size,
			});
		}
	};

	const getDeleteHandler = (i: number) => {
		return () => {
			if (allowMulti) {
				enrichedFiles.splice(i, 1);
				onChange([...enrichedFiles]);
			} else {
				onChange(null);
			}
		};
	};

	if (isLoading) {
		<Spinner />;
	}

	if (enrichedFiles?.length) {
		return (
			<>
				{label && (
					<Label htmlFor="fileDrop" required>
						{label}
					</Label>
				)}
				{enrichedFiles.map((file, index) => (
					<div className="px-4 mt-4 bg-white rounded-md" key={`${file.name}_${index}`}>
						<div className="flex items-center">
							<RenderFilePreview file={file} />

							<div className="flex justify-between flex-1 ml-4 md:flex-row flow-col">
								<RenderFileDetails file={file} />
								<Button size="sm" variant="ghost" className="!p-0" onClick={getDeleteHandler(index)}>
									<Trash2 size={16} />
								</Button>
							</div>
						</div>
					</div>
				))}
				{allowMulti ? (
					<Button variant="outline" size="sm" className="relative w-full p-4 mt-2 bg-white cursor-pointer">
						<input
							type="file"
							name={name}
							accept={allowedFileTypes.join(", ")}
							onChange={handleFileChange}
							id={id}
							className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
							max-size={maxSize}
							multiple={allowMulti}
						/>
						<div className="flex items-center justify-center w-full">
							<Plus size={16} />
							&nbsp;&nbsp;Add More
						</div>
					</Button>
				) : null}
			</>
		);
	}
	return (
		<div className="flex flex-col gap-y-2">
			{label && (
				<Label htmlFor="">
					{label}
					{required && <span className="text-red-500">*</span>}
				</Label>
			)}
			<div className="relative flex flex-col items-center p-8 border border-dotted rounded-md border-input">
				<input
					type="file"
					name={name}
					accept={allowedFileTypes.join(", ")}
					onChange={handleFileChange}
					id={id}
					className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
					max-size={maxSize}
					multiple={allowMulti}
				/>
				<div className="flex items-center justify-center mb-4 text-muted-foreground">
					<PlusIcon size={50} strokeWidth={1} />
				</div>
				<p className="text-xs text-muted-foreground">Drag and drop or click to upload</p>
				{allowedFileTypes?.length ? (
					<p className="text-xs text-muted-foreground">
						Allowed file types: {allowedFileTypes.map((type) => mime.getExtension(type)).join(", ")}
					</p>
				) : null}
				<span className="flex items-center justify-center text-10xl">{description}</span>
			</div>
		</div>
	);
}
