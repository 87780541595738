import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { endpoints } from "../../../endpoints";
import { CreateVolunteerSubmissionForm, CreateVolunteerSubmissionRequest } from "../../../types/submission";
import { fileUpload } from "../../../utils/upload-helper";
import { Bucket } from "../../../types/upload";
import { toast } from "react-toastify";

import VolunteerInput from "./input";
import ThankYouMessage from "./thankyou";
import { Spinner } from "../../../components/base/spinner";

const VolunteerForm = ({ className = "max-w-2xl p-6 mx-auto my-10 bg-white" }: { className?: string }) => {
	const { id = "" } = useParams();
	const [isSuccess, setIsSuccess] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { data: eventData } = useQuery({
		queryKey: endpoints.volunteer_submission.fetch_event.getKeys(id),
		queryFn: () => endpoints.volunteer_submission.fetch_event.query(id),
	});

	const { mutateAsync } = useMutation({
		mutationFn: (data: CreateVolunteerSubmissionRequest) => endpoints.volunteer_submission.create.query(data),
	});

	const onSubmit = async (data: CreateVolunteerSubmissionForm) => {
		const { submissionFiles, ...rest } = data;
		setIsLoading(true);
		try {
			const submissionLinks = await Promise.all(
				submissionFiles?.map((asset, idx) =>
					fileUpload(`volunteer_submission_${idx + 1}_${asset.name}_${id}`, asset.file as File, {
						bucket: Bucket.RAW_ASSETS,
						filePath: "volunteer_submission",
					}).then((res) => res.url),
				) ?? [],
			);

			const payload = {
				eventId: id,
				submissionLinks,
				...rest,
			};
			await mutateAsync(payload);
			setIsSuccess(true);
		} catch (err) {
			toast.error("Error while submitting the form");
		} finally {
			setIsLoading(false);
		}
	};

	if (!eventData) return <Spinner />;
	return (
		<div className={className}>
			{!isSuccess ? (
				<VolunteerInput eventData={eventData} onSubmit={onSubmit} isLoading={isLoading} />
			) : (
				<ThankYouMessage />
			)}
		</div>
	);
};

export default VolunteerForm;
