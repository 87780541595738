export enum Bucket {
	RAW_ASSETS = "raw_assets",
	OPTIMIZED_ASSETS = "optimized_assets",
}

export type Config = {
	bucket: Bucket;
	filePath: string;
};

export type PresignedUploadUrlRequestBody = {
	bucket: string;
	key: string;
	filePath: string;
};

export type PresignedUploadUrlResponse = {
	presignedUrl: string;
	assetUrl?: string;
};
