import { useQuery } from "@tanstack/react-query";
import { endpoints } from "../endpoints";
import { getEventState } from "../utils/get-event-state";
import { EventWithState } from "../types/event";

export function useEvent(eventId: string) {
	return useQuery({
		queryKey: ["EVENT", "GET", eventId],
		queryFn: () =>
			endpoints.event.get
				.query(eventId, {
					expand: ["goodfies", "collaterals", "client.clientContext", "partner.corporateAddress", "variant"],
					fields: [
						"id",
						"name",
						"startTimeStamp",
						"endTimeStamp",
						"timezone",
						"partner",
						"goodfies",
						"variant",
						"collaterals",
						"submissionDeadline",
					],
				})
				.then((event) => ({ ...event, state: getEventState(event) }) as EventWithState),
	});
}
