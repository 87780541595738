import React, { ChangeEventHandler, FocusEventHandler, MouseEventHandler, useImperativeHandle, useRef } from "react";

import clsx from "clsx";
import InputError from "./input-error";
import { MinusIcon, PlusIcon } from "lucide-react";

import { Label } from "./label";
import { cn } from "../../utils/tailwindcss";

export type InputProps = Omit<React.ComponentPropsWithRef<"input">, "prefix" | "className"> & {
	label?: string;
	onDelete?: MouseEventHandler<HTMLSpanElement>;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onFocus?: FocusEventHandler<HTMLInputElement>;
	prefix?: React.ReactNode;
	errors?: { [x: string]: unknown };
	props?: React.HTMLAttributes<HTMLDivElement>;
	hideNumberButtons?: boolean;
	tooltipContent?: React.ReactNode;
	className?: string | Record<string, boolean | undefined | null> | string[];
	containerClassName?: string | Record<string, boolean | undefined | null> | string[];
	inputContainerClassName?: string | Record<string, boolean | undefined | null> | string[];
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
	(
		{
			placeholder,
			label,
			name,
			required,
			tooltipContent,
			onChange,
			onFocus,
			prefix,
			errors,
			props,
			containerClassName,
			inputContainerClassName,
			className,
			hideNumberButtons,
			...fieldProps
		}: InputProps,
		ref,
	) => {
		const inputRef = useRef<HTMLInputElement | null>(null);

		useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => inputRef.current);

		const onNumberIncrement = () => {
			inputRef.current?.stepUp();
			if (onChange) {
				inputRef.current?.dispatchEvent(
					new InputEvent("change", {
						view: window,
						bubbles: true,
						cancelable: false,
					}),
				);
			}
		};

		const onNumberDecrement = () => {
			inputRef.current?.stepDown();
			if (onChange) {
				inputRef.current?.dispatchEvent(
					new InputEvent("change", {
						view: window,
						bubbles: true,
						cancelable: false,
					}),
				);
			}
		};

		return (
			<div className={clsx("flex flex-col gap-y-2 w-full", containerClassName)} {...props}>
				{label && (
					<Label htmlFor={name} tooltipContent={tooltipContent}>
						{label}
						{required && <span className="text-red-500">*</span>}
					</Label>
				)}
				<div
					className={clsx(
						"flex border text-sm border-input rounded-md px-3 py-2 w-full h-fit items-center gap-2 bg-background focus:border-secondary",
						inputContainerClassName,
					)}
				>
					{prefix ? <span className="mr-1">{prefix}</span> : null}
					<input
						type={fieldProps.type}
						className={cn(
							"w-full file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground/50 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
							className,
						)}
						ref={inputRef}
						autoComplete="off"
						name={name}
						placeholder={placeholder || (label ? `${label}...` : "")}
						onChange={onChange}
						onFocus={onFocus}
						onWheel={(e) => (e.target as HTMLInputElement).blur()}
						required={required}
						{...fieldProps}
					/>

					{fieldProps.type === "number" && !hideNumberButtons && (
						<div className="flex items-center h-full">
							<button
								onClick={onNumberDecrement}
								onMouseDown={(e) => e.preventDefault()}
								className="w-4 h-4 mr-2 outline-none cursor-pointer rounded-soft text-grey-50 hover:bg-grey-10 focus:bg-grey-20"
								type="button"
								tabIndex={-1}
							>
								<MinusIcon size={16} />
							</button>
							<button
								onMouseDown={(e) => e.preventDefault()}
								onClick={onNumberIncrement}
								className="w-4 h-4 outline-none cursor-pointer rounded-soft text-grey-50 hover:bg-grey-10 focus:bg-grey-20"
								type="button"
								tabIndex={-1}
							>
								<PlusIcon size={16} />
							</button>
						</div>
					)}
				</div>
				<InputError name={name} errors={errors} />
			</div>
		);
	},
);

Input.displayName = "Input";

export default Input;
