import { Camera, Download, Edit2, Eye, FormInputIcon, Upload, UploadCloudIcon } from "lucide-react";
import { Tile } from "./tile";
import { GoodfieCard } from "./goodfie-card";
import { useVrpContext } from ".";
import { useState } from "react";
import * as Dialog from "../../components/base/dialog";
import VolunteerForm from "../submission/[id]";
import { submissionCounter } from "../../utils/submission-counter";
import { CountdownText } from "./countdown-text";

export const AfterEvent = () => {
	const [openSubmissionForm, setOpenSubmissionForm] = useState(false);
	const { event, isCompletedEvent } = useVrpContext();

	const goodfies = event?.goodfies?.filter(({ showOnEventPage }) => showOnEventPage);

	if (!isCompletedEvent) {
		return null;
	}

	const { isDeadlinePassed } = submissionCounter(event.endTimeStamp, event.submissionDeadline);

	return (
		<div className="w-screen bg-white">
			<Dialog.Dialog open={openSubmissionForm} onOpenChange={setOpenSubmissionForm}>
				<Dialog.DialogContent className="max-w-2xl h-[85vh] overflow-scroll">
					<VolunteerForm className="" />
				</Dialog.DialogContent>
			</Dialog.Dialog>

			<div className="max-w-6xl px-4 mx-auto md:px-0">
				<div className="py-10 md:py-20">
					<div>
						<div className="mb-16 md:mb-20">
							<div className="mb-6 md:mb-11 text-xl md:text-2xl text-[#888888]">Photo Gallery</div>
							<div className="flex flex-col gap-6 md:gap-10">
								{goodfies?.length ? (
									<div>
										<Tile
											header="Thank you for volunteering with Goodera"
											icon={<Download />}
											iconBackgroundColor="#FFF0EB"
											text="Access the link to download and share event photos."
											buttonIcon={<Eye size={24} />}
											buttonText="View Gallery"
											hideButton
										/>
										<GoodfieCard goodfies={goodfies} />
									</div>
								) : (
									<Tile
										header="Event goodfie"
										icon={<Camera />}
										iconBackgroundColor="#FFF0EB"
										text="Will be available to download soon!"
										buttonIcon={<UploadCloudIcon size={24} />}
										buttonText="Uploading"
									/>
								)}
							</div>
						</div>

						<div>
							<div className="mb-6 md:mb-11 text-xl md:text-2xl text-[#888888]">Deliverables</div>
							<div className="flex flex-col gap-6 md:gap-10">
								<Tile
									header="Submissions"
									icon={<Upload />}
									iconBackgroundColor="#D6D8E5"
									text={<CountdownText />}
									buttonIcon={<Upload size={18} />}
									buttonText="Submission Form"
									onClick={() => setOpenSubmissionForm(true)}
									hidden={isDeadlinePassed}
								/>

								<Tile
									header="Haven’t shared your feedback?"
									icon={<Edit2 />}
									iconBackgroundColor="#FFF6D3"
									text="Your suggestions are valuable and help us improve our events."
									buttonIcon={<FormInputIcon size={24} />}
									buttonText="Feedback form"
									onClick={() =>
										window.open(process.env.REACT_APP_FEEDBACK_BASE_URL + event.id, "_blank")
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
