import { Address } from ".";

export type Event = {
	id: string;
	name: string;
	startTimeStamp: string;
	endTimeStamp: string;
	timezone: string;
	client: {
		name: string;
		logo: string;
		clientContext: {
			showLogoInEmails: boolean;
		};
	};
	partner: {
		name: string;
		corporateAddress: Address;
		videoUrl?: string;
	};

	goodfies: Goodfie[];
	submissionDeadline: number;
	variant: { format: EventFormat };
	collaterals?: EventCollateral[];
};

export enum EventState {
	UPCOMING = "upcoming",
	CANCELLED = "cancelled",
	ONGOING = "ongoing",
	COMPLETED = "completed",
}

export type Goodfie = {
	url: string;
	showOnEventPage: boolean;
};

export enum EventFormat {
	OUTDOOR = "outdoor",
	IN_OFFICE = "in_office",
	VIRTUAL = "virtual",
	KIOSK = "kiosk",
}

export type EventCollateral = {
	event: Event;
	eventId: string;
	url: string;
	collateralType: string;
};

export type EventWithState = Event & { state: EventState };
