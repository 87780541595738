import { useEffect, useState } from "react";
import { useVrpContext } from ".";
import { submissionCounter } from "../../utils/submission-counter";

export function CountdownText() {
	const { event } = useVrpContext();
	const [text, setText] = useState("");

	useEffect(() => {
		const interval = setInterval(() => {
			const { countdownText } = submissionCounter(event.endTimeStamp, event.submissionDeadline);
			setText(countdownText);
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [event]);

	return <>{text}</>;
}
