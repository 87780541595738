import { useQuery } from "@tanstack/react-query";
import { getFileDetails } from "../utils/get-file-details";
import { AdvancedFile, AdvancedFileOrUrl } from "../components/base/file-drop";

export function useEnrichedFiles(files: AdvancedFileOrUrl[]) {
	const { data: enrichedFiles, isLoading } = useQuery({
		queryKey: ["FILE_DETAILS", ...files],
		queryFn: () =>
			Promise.all(
				files.map((file) => {
					if ("name" in file) return file;

					return getFileDetails(file.url).then(
						(res) =>
							({
								name: res.fileName ?? "-",
								type: res.fileType ?? "",
								size: res.fileSize ?? -1,
								url: file.url,
							}) as AdvancedFile,
					);
				}),
			),
		initialData: [] as AdvancedFile[],
	});
	return { isLoading, enrichedFiles };
}
