import clsx from "clsx";

export type SpinnerProps = {
	size?: "large" | "medium" | "small";
	variant?: "primary" | "secondary";
	className?: string;
};

const Spinner = ({ size = "large", variant = "primary", className = "" }: SpinnerProps) => {
	return (
		<div
			className={clsx(
				"flex items-center justify-center",
				{ "h-6 w-6": size === "large" },
				{ "h-5 w-5": size === "medium" },
				{ "h-4 w-4": size === "small" },
				className,
			)}
		>
			<div className="relative flex items-center justify-center w-full h-full">
				<div
					className={clsx(
						"h-4/5 w-4/5 animate-spin rounded-full border-2  border-dotted",
						{ "border-secondary": variant === "primary" },
						{ "border-primary": variant === "secondary" },
					)}
				/>
			</div>
		</div>
	);
};

export { Spinner };
