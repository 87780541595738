import { endpoints } from "../../endpoints";
import { useParams } from "react-router-dom";
import QRCode from "react-qr-code";

import { useQuery } from "@tanstack/react-query";
import { ArrowUpRight, Link2Icon, MapPinIcon, MoveUpRight } from "lucide-react";
import moment from "moment-timezone";
import { getAddressLine } from "../../utils/get-address-line";
import { getMapUrl } from "../../utils/get-map-url.ts";

export const Ticket = () => {
	const { customerId = "", eventId = "" } = useParams();
	const { data: registrationData } = useQuery({
		queryKey: endpoints.ticket.getRegistrationTicket.getKeys(eventId, customerId),
		queryFn: () => endpoints.ticket.getRegistrationTicket.query({ customerId, eventId }),
	});

	if (!registrationData) return <></>;

	const timezone = registrationData.event.timezone || moment.tz.guess(); // Fallback to guessed timezone if none provided

	const startDateTime = moment.tz(registrationData.event.startTimeStamp, timezone);
	const date = startDateTime.format("DD");
	const month = startDateTime.format("MMMM");
	const year = startDateTime.format("YYYY");

	const timeString = `${startDateTime.format("LT")} - ${moment.tz(registrationData.event.endTimeStamp, timezone).format("LT")} (${moment.tz(timezone).zoneName()})`;

	const email = registrationData.email;
	const id = registrationData.registrationId;
	const event = registrationData.event;

	return (
		<div className="bg-[#F5F5F5] w-screen h-screen flex items-center justify-center font-buenos">
			<div className="md:scale-125 flex flex-col items-end text-[#888] gap-4">
				<div className="flex flex-col max-w-sm mx-4 bg-white rounded-2xl">
					<div className="flex px-12 pt-8">
						<div className="flex my-auto flex-col items-center text-xl leading-8 text-[#A6A6A6] font-semibold">
							<p>{month}</p>
							<p className="text-[50px] text-[#7A7A7A]">{date}</p>
							<p>{year}</p>
						</div>
						<div className="border-r border-[#E5E5E5] mx-4"></div>
						<div>
							<div className="font-semibold text-black">{event.name}</div>
							<p className="my-3 text-xs text-[#7A7A7A]">{timeString}</p>
						</div>
					</div>
					<div className="border-b-2 border-dashed border-[#E5E5E5] flex justify-between">
						<div className="-mb-4 -ml-[17px]">
							<div className="flex items-center justify-center w-8 h-8 rounded-full bg-[#F5F5F5]"></div>
						</div>
						<div className="-mb-4 -mr-[17px]">
							<div className="flex items-center justify-center w-8 h-8 rounded-full bg-[#F5F5F5]"></div>
						</div>
					</div>
					<div className="flex flex-col items-center py-5">
						<div className="bg-[#FAFAFA] px-6 py-6 rounded-lg flex flex-col gap-2 items-center">
							<QRCode value={id} size={150} />
							<div className="font text-[#222] text-xs">{email}</div>
						</div>
						<div className="flex items-center gap-1 mt-4 text-[#666] hover:text-goodera-primary w-full px-8">
							<div className="shrink-0">
								<MapPinIcon size={16} />
							</div>
							<a
								href={getMapUrl(event.address?.latitude, event.address?.longitude)}
								className="text-sm underline line-clamp-1"
							>
								{getAddressLine(event.address)}
							</a>
							<div className="shrink-0">
								<ArrowUpRight size={16} />
							</div>
						</div>
					</div>
				</div>
				<div className="flex items-center gap-1 mr-5 text-xs">
					Powered by
					<img alt="goodera logo" src="/goodera-logo.png" width={60} />
				</div>
			</div>
		</div>
	);
};

export default Ticket;
