import moment from "moment-timezone";
import { isAfter } from "date-fns";

export function submissionCounter(endTimeStamp: string, submissionDeadline_: number) {
	const submissionDeadline = new Date(endTimeStamp).getTime() + submissionDeadline_;
	const currentTime = moment().valueOf();

	const timeRemaining = submissionDeadline - currentTime;

	function formatTimeRemaining(ms: number) {
		const duration = moment.duration(ms);
		const totalHours = Math.floor(duration.asHours()); // Get total hours including days
		const minutes = duration.minutes();
		const seconds = duration.seconds();

		if (totalHours > 0) {
			return `${totalHours}h ${minutes}m ${seconds}s`;
		} else if (minutes > 0) {
			return `${minutes}m ${seconds}s`;
		} else {
			return `${seconds}s`;
		}
	}

	const isDeadlinePassed = isAfter(currentTime, submissionDeadline);

	const countdownText =
		timeRemaining > 0
			? `Submission portal closes in ${formatTimeRemaining(timeRemaining)}.`
			: "The submission deadline has passed.";

	return { isDeadlinePassed, countdownText };
}
