import { getFileName } from "./get-file-name";
import { endpoints } from "../endpoints";
import { Bucket, Config, PresignedUploadUrlResponse } from "../types/upload";

export const fileUpload = (filename: string, file: File, { bucket, filePath }: Config) => {
	const uniqueFileName = getFileName(filename, file);
	switch (bucket) {
		case Bucket.RAW_ASSETS:
			return presignedS3Uploader(file, uniqueFileName, filePath, bucket);
		case Bucket.OPTIMIZED_ASSETS: {
			return presignedCloudinaryUploader(file, uniqueFileName, filePath, bucket);
		}
	}
};

async function getPresignedAndAssetUrl(key: string, filePath: string, bucket: Bucket) {
	let data: PresignedUploadUrlResponse;
	try {
		data = await endpoints.file_service.signed_url_upload.query({
			bucket,
			key,
			filePath,
		});
		if (!data.presignedUrl) {
			throw new Error("No pre-signed url in response");
		}
	} catch (err) {
		console.error("error getting pre-signed url:", err);
		throw new Error("Error getting pre-signed URL");
	}
	return data;
}

async function presignedS3Uploader(file: File, key: string, filePath: string, bucket: Bucket) {
	const { presignedUrl, assetUrl } = await getPresignedAndAssetUrl(key, filePath, bucket);

	if (!assetUrl) {
		throw new Error("No assetUrl in response");
	}

	try {
		const res = await fetch(presignedUrl, {
			method: "PUT",
			body: file,
		});

		if (!res.ok) {
			throw new Error();
		}

		return { url: assetUrl };
	} catch (err) {
		console.error("error uploading the asset:", err);
		throw new Error("Error uploading file");
	}
}

async function presignedCloudinaryUploader(file: File, key: string, filePath: string, bucket: Bucket) {
	const { presignedUrl } = await getPresignedAndAssetUrl(key, filePath, bucket);

	try {
		const form = new FormData();
		form.append("file", file);

		const res = await fetch(presignedUrl, {
			method: "POST",
			body: form,
		});

		if (!res.ok) {
			throw new Error();
		}

		const data = (await res.json()) as { url: string; secure_url: string };

		return { url: data.secure_url };
	} catch (err) {
		console.error("error uploading the asset:", err);
		throw new Error("Error uploading file");
	}
}
