type FileFormats =
	| 'application'
	| 'audio'
	| 'font'
	| 'example'
	| 'image'
	| 'message'
	| 'model'
	| 'multipart'
	| 'text'
	| 'video';

export function getFileFormat(file: File): FileFormats {
	const fileType = file.type;
	return fileType.split('/')[0] as FileFormats;
}

export function getFileFormatFromType(fileType?: string) {
	if (!fileType) return null;
	return fileType.split('/')[0] as FileFormats;
}
